<template>
  <div>
    <vs-tabs class="contact-tab mt-8">
      <vs-tab label="Campaign Defaults" icon-pack="feather" icon="icon-user">
        <div class="campaign-details">
          <form data-vv-scope="defaultCampaign">
            <div class="vx-row">
              <div class="vx-col md:w-1/3 mb-6 w-full">
                <vs-input
                  class="w-full"
                  label="Default 'From' Name"
                  v-model="fromName"
                  v-validate="'required'"
                  name="fromName"
                />
                <span class="text-danger text-sm">{{
                  errors.first("defaultCampaign.fromName")
                }}</span>
              </div>
              <div class="vx-col md:w-1/3 mb-6 w-full">
                <vs-input
                  class="w-full"
                  label="Default 'From' email Address"
                  v-model="fromEmail"
                  v-validate="'required'"
                  name="fromEmail"
                />
                <span class="text-danger text-sm">{{
                  errors.first("defaultCampaign.fromEmail")
                }}</span>
              </div>
              <div class="vx-col md:w-1/3 mb-6 w-full">
                <vs-input
                  class="w-full"
                  label="Default email Subject"
                  v-model="emailSubject"
                  v-validate="'required'"
                  name="emailSubject"
                />
                <span class="text-danger text-sm">{{
                  errors.first("defaultCampaign.emailSubject")
                }}</span>
              </div>
              <div class="vx-col md:w-1/2 mb-6 w-full">
                <span class="vs-input--label w-full float-left"
                  >Street Address</span
                >
                <vs-textarea
                  v-model="address1"
                  class="mb-0"
                  name="address1"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("defaultCampaign.address1")
                }}</span>
              </div>
              <div class="vx-col md:w-1/2 mb-6 w-full">
                <span class="vs-input--label w-full float-left"
                  >Address line 2</span
                >
                <vs-textarea v-model="address2" class="mb-0" name="address2" />
                <span class="text-danger text-sm"></span>
              </div>
              <div class="vx-col md:w-1/2 mb-6 w-full">
                <vs-input
                  class="w-full"
                  label="City"
                  name="cityName"
                  v-model="cityName"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("defaultCampaign.cityName")
                }}</span>
              </div>
              <div class="vx-col md:w-1/2 mb-6 w-full">
                <vs-input
                  class="w-full"
                  label="State"
                  name="stateName"
                  v-model="stateName"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("defaultCampaign.stateName")
                }}</span>
              </div>
              <div class="vx-col md:w-1/2 mb-6 w-full">
                <vs-input
                  class="w-full"
                  label="Zip"
                  name="zipNumber"
                  v-model="zipNumber"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("defaultCampaign.zipNumber")
                }}</span>
              </div>
              <div class="vx-col md:w-1/2 mb-6 w-full">
                <span class="vs-input--label w-full float-left">Country</span>
                <v-select
                  v-model="country"
                  label="Country_Name_Long"
                  name="country"
                  v-validate="'required'"
                  placeholder="Country"
                  :options="CountryOptions"
                  :reduce="(CountryOptions) => CountryOptions.Id"
                />
                <span class="text-danger text-sm">{{
                  errors.first("defaultCampaign.country")
                }}</span>
              </div>
              <div class="vx-col md:w-1/2 mb-6 w-full">
                <vs-input
                  class="w-full"
                  label="Phone Number"
                  name="phonenumber"
                  v-model="phoneNumber"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("defaultCampaign.phonenumber")
                }}</span>
              </div>
              <div class="vx-col md:w-1/2 mb-6 w-full">
                <vs-input
                  class="w-full"
                  label="Website URL"
                  name="websiteurl"
                  v-model="websiteurl"
                  v-validate="'required|url'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("defaultCampaign.websiteurl")
                }}</span>
              </div>
            </div>
          </form>
          <div class="float-left w-full text-right">
            <vs-button
              type="filled"
              color="primary"
              class="sm:w-auto w-full sm:mb-0 mb-2"
              @click="updateDefaultCampaignDetails()"
              >{{ LabelConstant.buttonLabelSaveCampaignDefaults }}</vs-button
            >
            <vs-button
              color="grey"
              type="border"
              class="grey-btn sm:ml-4 ml-0 sm:w-auto w-full"
              >{{ LabelConstant.buttonLabelReset }}</vs-button
            >
          </div>
        </div>
      </vs-tab>

      <vs-tab label="Custom Fields" icon-pack="feather" icon="icon-settings">
        <div class="custom-fields">
          <div class="flex justify-end w-auto mb-4 w-full text-right">
            <vs-button
              @click="addFieldActive = true"
              class="bg-actionbutton small-btn"
              icon-pack="feather"
              icon="icon-plus"
              type="filled"
              >{{ LabelConstant.buttonLabelAddaField }}</vs-button
            >
          </div>

          <div class="main-table-data">
            <div class="table">
              <div class="theader">
                <div class="table_header">Field label</div>
                <div class="table_header">Type</div>
                <div class="table_header">Required?</div>
                <div class="table_header">Visible?</div>
                <div class="table_header">Put this tag in your content</div>
                <div class="table_header">Default merege tag value</div>
                <div class="table_header"></div>
              </div>
              <div
                class="table_row"
                v-for="(customfield, index) in customsFields"
                :key="index"
              >
                <div class="table_small">
                  <div class="table_cell">
                    <div class="first-input">
                      <vs-input
                        class="mr-2"
                        :disabled="!customfield.Edit"
                        v-model="customfield.Field_Label"
                        placeholder="Enter Field Label"
                      />
                      <div
                        class="untitle-option-select"
                        v-if="
                          customfield.Field_Type.Field_Type == 'dropdown' ||
                          customfield.Field_Type.Field_Type == 'radio'
                        "
                      >
                        <div
                          class="mt-2 float-left w-full"
                          v-for="(op, index) in customfield.Options"
                          :key="index"
                        >
                          <vs-input
                            v-model="op.Possible_Value"
                            :disabled="!customfield.Edit"
                          />
                          <vs-button
                            :disabled="!customfield.Edit"
                            @click="addEditOptions(customfield.Id)"
                            color="success"
                            type="filled"
                            icon-pack="feather"
                            icon="icon-plus"
                          ></vs-button>
                          <vs-button
                            :disabled="!customfield.Edit"
                            @click="removeEditOption(index, customfield.Id)"
                            v-if="customfield.Options.length > 1"
                            color="danger"
                            type="filled"
                            icon-pack="feather"
                            icon="icon-minus"
                            class="ml-2"
                          ></vs-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="table_small text-center">
                  <div class="table_cell">
                    <p>{{ firstCapital(customfield.Field_Type.Field_Type) }}</p>
                  </div>
                </div>
                <div class="table_small">
                  <div class="table_cell text-center">
                    <vs-checkbox
                      v-model="customfield.Required"
                      :disabled="!customfield.Edit"
                      color="primary"
                    ></vs-checkbox>
                  </div>
                </div>
                <div class="table_small">
                  <div class="table_cell text-center">
                    <vs-checkbox
                      v-model="customfield.Visible"
                      :disabled="!customfield.Edit"
                      color="primary"
                    ></vs-checkbox>
                  </div>
                </div>
                <div class="table_small">
                  <div class="table_cell">
                    <div class="merge-tag">
                      <span>
                        <vs-input
                          :disabled="!customfield.Edit"
                          v-model="customfield.Merge_Tag"
                        />
                      </span> or
                      <span>*Merge{{ index + 1 }}*</span>
                    </div>
                  </div>
                </div>
                <div class="table_small">
                  <div class="table_cell">
                    <vs-input
                      class="mr-2"
                      v-if="customfield.Field_Type.Field_Type == 'text' || customfield.Field_Type.Field_Type == 'string'"
                      v-model="customfield.Default_Value"
                      :disabled="!customfield.Edit"
                    />
                    <vs-input
                      class="mr-2"
                      v-if="customfield.Field_Type.Field_Type == 'number'"
                      name="customNumeric"
                      v-validate="'numeric'"
                      v-model="customfield.Default_Value"
                    />
                    <span
                      class="text-danger error-msg"
                      v-if="customfield.Field_Type.Field_Type == 'number'"
                      >{{ errors.first("customNumeric") }}</span
                    >
                    <flat-pickr
                      v-model="customfield.Default_Value"
                      :disabled="!customfield.Edit"
                      v-if="customfield.Field_Type.Field_Type == 'birthday' || customfield.Field_Type.Field_Type == 'date'"
                    />
                    <vs-input
                      class="mr-2"
                      :disabled="!customfield.Edit"
                      v-if="customfield.Field_Type.Field_Type == 'phone'"
                      v-model="customfield.Default_Value"
                      v-validate="'phone_number'"
                      name="phone"
                    />
                    <span
                      class="text-danger error-msg"
                      v-if="customfield.Field_Type.Field_Type == 'phone'"
                      >{{ errors.first("phone") }}</span
                    >
                    <vs-input
                      class="mr-2"
                      :disabled="!customfield.Edit"
                      v-if="customfield.Field_Type.Field_Type == 'zip'"
                      v-model="customfield.Default_Value"
                      v-validate="'zip_code|max:10'"
                      name="zip"
                    />
                    <span
                      class="text-danger error-msg"
                      v-if="customfield.Field_Type.Field_Type == 'zip'"
                      >{{ errors.first("zip") }}</span
                    >
                    <vs-input
                      class="mr-2"
                      :disabled="!customfield.Edit"
                      v-if="customfield.Field_Type.Field_Type == 'website'"
                      v-validate="'url'"
                      v-model="customfield.Default_Value"
                      name="website"
                    />
                    <span
                      class="text-danger error-msg"
                      v-if="customfield.Field_Type.Field_Type == 'website'"
                      >{{ errors.first("website") }}</span
                    >
                    <vs-input
                      type="file"
                      ref="file"
                      name="Image"
                      v-if="customfield.Field_Type.Field_Type == 'image' && customfield.Edit"
                      @change="changeEditImage($event, customfield.Id)"
                    />
                    <img
                      :src="'data:image/png;base64, ' + customfield.Default_Value"
                      v-if="customfield.Field_Type.Field_Type == 'image' && !customfield.Edit"
                      width="60"
                      height="60"
                    />
                    <v-select
                      :disabled="!customfield.Edit"
                      v-if="(customfield.Field_Type.Field_Type == 'radio' || customfield.Field_Type.Field_Type == 'dropdown') && customfield.Options != null"
                      v-model="customfield.Default_Value"
                      placeholder="Select Default Value"
                      :options="customfield.Options"
                      :reduce="(customOptions) => customOptions.Possible_Value"
                      label="Possible_Value"
                    />
                  </div>
                </div>
                <div class="table_small">
                  <div
                    class="table_cell table-action-btn"
                    v-if="!customfield.Edit"
                  >
                    <vs-button
                      @click="editCustomField(customfield.Id)"
                      title="Edit"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-edit"
                      class="mr-2 float-left"
                      color="primary"
                    ></vs-button>
                    <vs-button
                      title="Delete"
                      @click="deleteFeildActive = true,customFieldDeleteId = customfield.Id"
                      color="danger"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-trash-2"
                      class="mr-0"
                    ></vs-button>
                  </div>
                  <div class="table_cell" v-else>
                    <span>
                      <vs-button
                        type="filled"
                        color="primary"
                        icon-pack="feather"
                        icon="icon-save"
                        class="mr-2 float-left"
                        @click="updateCustomField(customfield)"
                      ></vs-button>
                      <vs-button
                        @click="getCustomField()"
                        color="grey"
                        icon-pack="feather"
                        icon="icon-x"
                        type="border"
                        class="mr-0 grey-btn"
                      ></vs-button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--<vs-table class="table-list" ref="table" :data="customsFields">
            <template slot="thead">
              <vs-th>Field label and type</vs-th>
              <vs-th>Required?</vs-th>
              <vs-th>Visible?</vs-th>
              <vs-th>Put this tag in your content</vs-th>
              <vs-th>Default merege tag value </vs-th>
              <vs-th></vs-th>
            </template>
            <template slot-scope="{ data }">
              <tbody>
                <vs-tr v-for="(tr, index) in data" :key="index">
                  <vs-td class="field-center">
                    <vs-input
                      :disabled="!tr.Edit"
                      v-model="tr.Field_Label"
                      class="mr-2"
                    />
                    <vs-input
                      :disabled="!tr.Edit"
                      v-if="tr.Field_Type.Field_Type == 'string'"
                      v-model="tr.Current_Value"
                      :placeholder="tr.Field_Label"
                    />
                    <flat-pickr
                      :disabled="!tr.Edit"
                      v-model="tr.Current_Value"
                      v-if="tr.Field_Type.Field_Type == 'DateTime'"
                    />
                  </vs-td>
                  <vs-td>
                    <vs-checkbox
                      v-model="tr.Required"
                      color="primary"
                      :disabled="!tr.Edit"
                    ></vs-checkbox>
                  </vs-td>
                  <vs-td>
                    <vs-checkbox
                      v-model="tr.Visible"
                      color="primary"
                      :disabled="!tr.Edit"
                    ></vs-checkbox>
                  </vs-td>
                  <vs-td>
                    <div class="merge-tag">
                      <span>
                        <vs-input v-model="tr.Merge_Tag" :disabled="!tr.Edit"
                      /></span>
                      or
                      <span>*Merge{{ index + 1 }}*</span>
                    </div>
                  </vs-td>
                  <vs-td> -->
          <!-- <vs-input
                      name="meregeTag"
                      placeholder="Default merge tag value"
                    /> -->
          <!-- <vs-input
                      :disabled="!tr.Edit"
                      v-if="tr.Field_Type.Field_Type == 'string'"
                      v-model="tr.Default_Value"
                    />
                    <flat-pickr
                      :disabled="!tr.Edit"
                      v-model="tr.Default_Value"
                      v-if="tr.Field_Type.Field_Type == 'DateTime'"
                    />
                    <v-select
                      v-if="
                        tr.Field_Type.Field_Type == 'radio' ||
                        tr.Field_Type.Field_Type == 'dropdown'
                      "
                      :disabled="!tr.Edit"
                      v-model="tr.Default_Value"
                      placeholder="Select Default Value"
                      :options="tr.Options"
                    />
                  </vs-td>
                  <vs-td v-if="!tr.Edit">
                    <div
                      class="edit-delete-label"
                      style="width: 85px; float: left"
                    >
                      <vs-button
                        @click="editCustomField(tr.Id, index)"
                        title="Edit"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-edit"
                        class="mr-2 float-left"
                        color="primary"
                      ></vs-button>
                      <vs-button
                        title="Delete"
                        @click="
                          (deleteFeildActive = true),
                            (customFieldDeleteId = tr.Id)
                        "
                        color="danger"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-trash-2"
                        class="mr-0"
                      ></vs-button>
                    </div>
                  </vs-td>
                  <vs-td v-else>
                    <vs-button
                      type="filled"
                      color="primary"
                      @click="updateCustomField(tr)"
                      >{{ LabelConstant.buttonLabelSave }}</vs-button
                    >
                    <vs-button
                      @click="getCustomField()"
                      color="grey"
                      type="border"
                      class="ml-4 grey-btn"
                      >{{ LabelConstant.buttonLabelCancel }}</vs-button
                    >
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table> -->
          <!-- <div class="float-right mt-6 mb-8">
            <vs-button type="filled" button="submit" color="primary"
              >Save Changes</vs-button
            >
          </div> -->
        </div>
      </vs-tab>

      <!-- Senders -->
      <vs-tab label="Senders" icon-pack="feather" icon="icon-send">
        <vx-card class="senders">
          <div class="card-title justify-end">
            <vs-button
              color="primary"
              @click="addSenderActive = true"
              icon-pack="feather"
              icon="icon-plus"
              type="filled"
              >{{ LabelConstant.buttonLabelAddSender }}</vs-button
            >
          </div>
          <vs-table class="table-list" ref="table" :data="senders">
            <template slot="thead">
              <vs-th></vs-th>
              <vs-th sort-key="friendlyname">Friendly Name</vs-th>
              <vs-th sort-key="email">From Email</vs-th>
              <vs-th sort-key="name">From Name</vs-th>
              <vs-th sort-key="address">Address</vs-th>
            </template>
            <template slot-scope="{ data }">
              <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td width="130">
                    <vs-button
                      title="Edit"
                      @click="getSender(tr.Id)"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-edit"
                      class="mr-2 float-left"
                      color="primary"
                    ></vs-button>
                    <vs-button
                      title="Delete"
                      @click="deleteSenderConfirmation(tr)"
                      color="danger"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-trash-2"
                      class="mr-0 float-left"
                    ></vs-button>
                  </vs-td>
                  <vs-td>
                    <p>{{ tr.Friendly_Name ? tr.Friendly_Name : "" }}</p>
                  </vs-td>
                  <vs-td>
                    <p>{{ tr.From_Email }}</p>
                  </vs-td>
                  <vs-td>
                    <p>{{ tr.From_Name }}</p>
                  </vs-td>
                  <vs-td>
                    <p>{{ tr.Address1 }}</p>
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </vx-card>
      </vs-tab>

      <!-- Notifications -->
      <vs-tab label="Notifications" icon-pack="feather" icon="icon-bell">
        <div class="send-notification">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 mt-4 w-full">
              <div class="send-item">
                <h3>
                  <vs-checkbox
                    v-model="Email_On_Unsubscribe"
                    color="primary"
                  ></vs-checkbox>
                  <span class="title-span"
                    >Send Email Notification when contact unsubscribes.</span
                  >
                </h3>
                <div class="add-email-address">
                  <div
                    class="add-new-email"
                    v-for="(emailaddress, index) in Email_On_Unsubscribe_Addresses"
                    :key="index"
                    >
                    <vs-input
                      type="email"
                      placeholder="Enter a valid email address"
                      v-model="emailaddress.Unsubscribeemail"
                    />
                    <vs-button
                      @click="addEmail('U')"
                      color="success"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-plus"
                    ></vs-button>
                    <vs-button
                      @click="removeEmail('U', index)"
                      v-if="Email_On_Unsubscribe_Addresses.length > 1"
                      color="danger"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-minus"
                      class="ml-2"
                    ></vs-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-col md:w-1/2 mt-4 w-full">
              <div class="send-item">
                <h3>
                  <vs-checkbox
                    v-model="Email_On_Contact_Change"
                    color="primary"
                  ></vs-checkbox>
                  <span class="title-span"
                    >Send an end of day summury of contact changes.</span
                  >
                </h3>
                <div class="add-email-address">
                  <div
                    class="add-new-email"
                    v-for="(emailaddress, index) in Email_On_Contact_Change_Addresses"
                    :key="index"
                  >
                    <vs-input
                      v-model="emailaddress.ContactChangeemail"
                      placeholder="Enter a valid email address"
                    />
                    <vs-button
                      @click="addEmail('C')"
                      color="success"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-plus"
                    ></vs-button>
                    <vs-button
                      v-if="Email_On_Contact_Change_Addresses.length > 1"
                      @click="removeEmail('C', index)"
                      color="danger"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-minus"
                      class="ml-2"
                    ></vs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="float-right mt-6 mb-8">
            <vs-button
              type="filled"
              button="submit"
              color="primary"
              @click="updateDefaultNotificationSettings()"
              >{{ LabelConstant.buttonLabelSaveNotification }}</vs-button
            >
          </div>
        </div>
      </vs-tab>

      <!-- Import History -->
      <vs-tab label="Import History" icon-pack="feather" icon="icon-download">
        <div class="import-history">
          <div class="flex justify-end w-auto mt-4 w-full text-right">
            <vs-button class="bg-actionbutton small-btn" type="filled"
              >{{ LabelConstant.buttonLabelStartNewImport }}</vs-button
            >
          </div>
          <ul>
            <li v-for="(data, index) in importedData" :key="index">
              <span class="left-success-icon text-success"
                ><feather-icon icon="CheckIcon"></feather-icon
              ></span>
              <div class="history-details">
                <div class="left-text">
                  <h3>
                    {{ data.Import_Name
                    }}<span>{{ formattedDate(data.Import_Date) }}</span>
                  </h3>
                  <p>
                    {{ data.Contacts_Imported_Successfully }} contacts were
                    added to your audience
                  </p>
                  <p>
                    {{ data.Invalid_Contacts }} emails were either invalid or
                    <b>role-based</b> and could not be imported
                  </p>
                </div>
                <div class="action-icons">
                  <vs-button
                    @click.prevent="undoImport(data.Id)"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-rotate-ccw"
                  ></vs-button>
                  <vs-button
                    color="success"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-eye"
                  ></vs-button>
                </div>
              </div>
            </li>

            <!--<li>
              <span class="left-success-icon text-warning"
                ><feather-icon icon="AlertCircleIcon"></feather-icon
              ></span>
              <div class="history-details">
                <div class="left-text">
                  <h3>
                    Import from Copy/Pasted File <span>25/09/2020 9:58 PM</span>
                  </h3>
                  <p>No Contacts were added to your audience.</p>
                </div>
                <div class="action-icons">
                  <vs-button
                    color="primary"
                    disabled
                    type="filled"
                    icon-pack="feather"
                    icon="icon-rotate-ccw"
                  ></vs-button>
                  <vs-button
                    color="success"
                    disabled
                    type="filled"
                    icon-pack="feather"
                    icon="icon-eye"
                  ></vs-button>
                </div>
              </div>
            </li>-->
          </ul>
        </div>
      </vs-tab>
    </vs-tabs>

    <!-- Add Sender Modal -->
    <vs-popup
      class="popup-width"
      :title="senderId == 0 ? 'Add Sender' : 'Edit Sender'"
      :active.sync="addSenderActive"
    >
      <template>
        <form data-vv-scope="senderForm">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 mb-6 w-full">
              <vs-input
                class="w-full"
                label="Friendly Name"
                v-model="friendlyname"
                v-validate="'required'"
                name="friendlyname"
              />
              <span class="text-danger text-sm">{{
                errors.first("senderForm.friendlyname")
              }}</span>
            </div>
            <div class="vx-col md:w-1/2 mb-6 w-full">
              <vs-input
                class="w-full"
                label="Default 'From' Name"
                v-model="senderFromName"
                v-validate="'required'"
                name="senderFromName"
              />
              <span class="text-danger text-sm">{{
                errors.first("senderForm.senderFromName")
              }}</span>
            </div>
            <div class="vx-col md:w-1/2 mb-6 w-full">
              <vs-input
                class="w-full"
                label="Default 'From' email Address"
                v-model="senderFromEmail"
                v-validate="'required'"
                name="senderFromEmail"
              />
              <span class="text-danger text-sm">{{
                errors.first("senderForm.senderFromEmail")
              }}</span>
            </div>
            <div class="vx-col md:w-1/2 mb-6 w-full">
              <vs-input
                class="w-full"
                label="Default email Subject"
                v-model="senderSubject"
                v-validate="'required'"
                name="senderSubject"
              />
              <span class="text-danger text-sm">{{
                errors.first("senderForm.senderSubject")
              }}</span>
            </div>
            <div class="vx-col md:w-1/2 mb-6 w-full">
              <span class="vs-input--label w-full float-left"
                >Street Address</span
              >
              <vs-textarea
                v-validate="'required'"
                v-model="senderAddress1"
                class="mb-0 sender-area"
                name="senderAddress1"
              />
              <span class="text-danger text-sm">{{
                errors.first("senderForm.senderAddress1")
              }}</span>
            </div>
            <div class="vx-col md:w-1/2 mb-6 w-full">
              <span class="vs-input--label w-full float-left"
                >Address line 2</span
              >
              <vs-textarea
                v-model="senderAddress2"
                class="mb-0 sender-area"
                name="senderAddress2"
              />
              <span class="text-danger text-sm">{{
                errors.first("senderForm.senderAddress2")
              }}</span>
            </div>
            <div class="vx-col md:w-1/2 mb-6 w-full">
              <vs-input
                class="w-full"
                label="City"
                name="senderCity"
                v-model="senderCity"
                v-validate="'required'"
              />
              <span class="text-danger text-sm">{{
                errors.first("senderForm.senderCity")
              }}</span>
            </div>
            <div class="vx-col md:w-1/2 mb-6 w-full">
              <vs-input
                class="w-full"
                label="State"
                name="senderState"
                v-model="senderState"
                v-validate="'required'"
              />
              <span class="text-danger text-sm">{{
                errors.first("senderForm.senderState")
              }}</span>
            </div>
            <div class="vx-col md:w-1/2 mb-6 w-full">
              <vs-input
                class="w-full"
                label="Zip"
                name="senderZip"
                v-model="senderZip"
                v-validate="'required'"
              />
              <span class="text-danger text-sm">{{
                errors.first("senderForm.senderZip")
              }}</span>
            </div>
            <div class="vx-col md:w-1/2 mb-6 w-full">
              <span class="vs-input--label w-full float-left">Country</span>
              <v-select
                v-model="senderCountry"
                v-validate="'required'"
                name="senderCountry"
                label="Country_Name_Long"
                placeholder="Actions"
                :options="CountryOptions"
                :reduce="(CountryOptions) => CountryOptions.Id"
              />
              <span class="text-danger text-sm">{{
                errors.first("senderForm.senderCountry")
              }}</span>
            </div>
            <div class="vx-col md:w-1/2 mb-6 w-full">
              <vs-input
                class="w-full"
                label="Phone Number"
                name="senderNumber"
                v-model="senderNumber"
                v-validate="'required'"
              />
              <span class="text-danger text-sm">{{
                errors.first("senderForm.senderNumber")
              }}</span>
            </div>
            <div class="vx-col md:w-1/2 mb-6 w-full">
              <vs-input
                class="w-full"
                label="Website URL"
                name="senderWebsite"
                v-model="senderWebsite"
                v-validate="'required|url'"
              />
              <span class="text-danger text-sm">{{
                errors.first("senderForm.senderWebsite")
              }}</span>
            </div>
          </div>
        </form>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button type="filled" color="primary" @click="addUpdateSender()">{{
          senderId == 0 ? LabelConstant.buttonLabelSaveSender : LabelConstant.buttonLabelUpdateSender
        }}</vs-button>
        <vs-button
          @click="resetSender()"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          >{{  LabelConstant.buttonLabelReset }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Delete Sender Modal -->
    <vs-popup class="delete-popup" :active.sync="deleteSenderActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Are you sure to delete this Sender?</h4>
            <p>{{ senderFromName }}</p>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button color="danger" type="filled" @click="deleteSender()"
          >{{ LabelConstant.buttonLabelDelete }}</vs-button
        >
        <vs-button
          color="grey"
          @click="deleteSenderActive = false,senderId = 0,senderFromName = ''"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Add Field Modal -->
    <vs-popup
      class="popup-width"
      title="Add a Field"
      :active.sync="addFieldActive"
    >
      <div class="add-field">
        <h3>Select Field Type</h3>
        <ul class="field-list">
          <li
            @click="customFieldType('text')"
            :class="addCustomField.Field_Type == 'text' ? 'active' : ''"
          >
            <a href="#">Text</a>
          </li>
          <li
            @click="customFieldType('number')"
            :class="addCustomField.Field_Type == 'number' ? 'active' : ''"
          >
            <a href="#">Number</a>
          </li>
          <li
            @click="customFieldType('radio')"
            :class="addCustomField.Field_Type == 'radio' ? 'active' : ''"
          >
            <a href="#">Radio Button</a>
          </li>
          <li
            @click="customFieldType('dropdown')"
            :class="addCustomField.Field_Type == 'dropdown' ? 'active' : ''"
          >
            <a href="#">Drop Down</a>
          </li>
          <li
            @click="customFieldType('date')"
            :class="addCustomField.Field_Type == 'date' ? 'active' : ''"
          >
            <a href="#">Date</a>
          </li>
          <li
            @click="customFieldType('birthday')"
            :class="addCustomField.Field_Type == 'birthday' ? 'active' : ''"
          >
            <a href="#">Birthday</a>
          </li>
          <li
            @click="customFieldType('zip')"
            :class="addCustomField.Field_Type == 'zip' ? 'active' : ''"
          >
            <a href="#">Zip Code (US Only)</a>
          </li>
          <li
            @click="customFieldType('phone')"
            :class="addCustomField.Field_Type == 'phone' ? 'active' : ''"
          >
            <a href="#">Phone</a>
          </li>
          <li
            @click="customFieldType('website')"
            :class="addCustomField.Field_Type == 'website' ? 'active' : ''"
          >
            <a href="#">Website</a>
          </li>
          <li
            @click="customFieldType('image')"
            :class="addCustomField.Field_Type == 'image' ? 'active' : ''"
          >
            <a href="#">Image</a>
          </li>
        </ul>
        <div class="custom-fields">
          <div class="main-table-data">
            <div class="table">
              <div class="theader">
                <div class="table_header">Field label</div>
                <div class="table_header">Required?</div>
                <div class="table_header">Visible?</div>
                <div class="table_header">Put this tag in your content</div>
                <div class="table_header">Default merege tag value</div>
              </div>
              <div class="table_row">
                <div class="table_small">
                  <div class="table_cell">
                    <div class="first-input">
                      <vs-input
                        class="mr-2"
                        v-model="addCustomField.Field_Label"
                        placeholder="Enter Field Label"
                      />
                      <div
                        class="untitle-option-select"
                        v-if="addCustomField.Field_Type == 'dropdown' || addCustomField.Field_Type == 'radio'"
                      >
                        <div
                          class="mt-2 float-left w-full"
                          v-for="(op, index) in customOptions"
                          :key="index"
                        >
                          <vs-input v-model="op.Possible_Value" />
                          <vs-button
                            @click="addOptions()"
                            color="success"
                            type="filled"
                            icon-pack="feather"
                            icon="icon-plus"
                          ></vs-button>
                          <vs-button
                            @click="removeOption(index)"
                            v-if="customOptions.length > 1"
                            color="danger"
                            type="filled"
                            icon-pack="feather"
                            icon="icon-minus"
                            class="ml-2"
                          ></vs-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="table_small">
                  <div class="table_cell text-center">
                    <vs-checkbox
                      v-model="addCustomField.Required"
                      color="primary"
                    ></vs-checkbox>
                  </div>
                </div>
                <div class="table_small">
                  <div class="table_cell text-center">
                    <vs-checkbox
                      v-model="addCustomField.Visible"
                      color="primary"
                    ></vs-checkbox>
                  </div>
                </div>
                <div class="table_small">
                  <div class="table_cell">
                    <div class="merge-tag">
                      <span>
                        <vs-input v-model="addCustomField.Merge_Tag" />
                      </span>
                      or
                      <span>*Merge{{ customsFields.length + 1 }}*</span>
                    </div>
                  </div>
                </div>
                <div class="table_small">
                  <div class="table_cell">
                    <vs-input
                      class="mr-2"
                      v-if="addCustomField.Field_Type == 'text'"
                      v-model="addCustomField.Default_Value"
                    />
                    <vs-input
                      class="mr-2"
                      v-if="addCustomField.Field_Type == 'number'"
                      name="customNumeric"
                      v-validate="'numeric'"
                      v-model="addCustomField.Default_Value"
                    />
                    <span
                      class="text-danger error-msg"
                      v-if="addCustomField.Field_Type == 'number'"
                      >{{ errors.first("customNumeric") }}</span
                    >
                    <flat-pickr
                      v-model="addCustomField.Default_Value"
                      v-if="addCustomField.Field_Type == 'birthday' || addCustomField.Field_Type == 'date'"
                    />
                    <vs-input
                      class="mr-2"
                      v-if="addCustomField.Field_Type == 'phone'"
                      v-model="addCustomField.Default_Value"
                      v-validate="'phone_number'"
                      name="phone"
                    />
                    <span
                      class="text-danger error-msg"
                      v-if="addCustomField.Field_Type == 'phone'"
                      >{{ errors.first("phone") }}</span
                    >
                    <vs-input
                      class="mr-2"
                      v-if="addCustomField.Field_Type == 'zip'"
                      v-model="addCustomField.Default_Value"
                      v-validate="'zip_code|max:10'"
                      name="zip"
                    />
                    <span
                      class="text-danger error-msg"
                      v-if="addCustomField.Field_Type == 'zip'"
                      >{{ errors.first("zip") }}</span
                    >
                    <vs-input
                      class="mr-2"
                      v-if="addCustomField.Field_Type == 'website'"
                      v-validate="'url'"
                      v-model="addCustomField.Default_Value"
                      name="website"
                    />
                    <span
                      class="text-danger error-msg"
                      v-if="addCustomField.Field_Type == 'website'"
                      >{{ errors.first("website") }}</span
                    >
                    <vs-input
                      type="file"
                      ref="file"
                      name="Image"
                      v-if="addCustomField.Field_Type == 'image'"
                      @change="changeImage($event)"
                    />
                    <v-select
                      v-if="addCustomField.Field_Type == 'radio' || addCustomField.Field_Type == 'dropdown'"
                      v-model="addCustomField.Default_Value"
                      placeholder="Select Default Value"
                      :options="customOptions"
                      :reduce="(customOptions) => customOptions.Possible_Value"
                      label="Possible_Value"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--<vs-table class="table-list" ref="table" :data="addCustomField">
            <template slot="thead">
              <vs-th>Field label</vs-th>
              <vs-th>Required?</vs-th>
              <vs-th>Visible?</vs-th>
              <vs-th>Put this tag in your content</vs-th>
              <vs-th>Default merege tag value </vs-th>
            </template>
            <template slot-scope="{ data }">
              <tbody>
                <vs-tr v-for="(tr, index) in data" :key="index">
                  <vs-td class="field-center">
                    <vs-input
                      v-model="tr.Field_Label"
                      placeholder="Enter Field Lable"
                      type="text"
                    />

                    <div
                      class="untitle-option-select"
                      v-if="
                        tr.Field_Type == 'dropdown' || tr.Field_Type == 'radio'
                      "
                    >
                      <div
                        class="flex items-center mt-3"
                        v-for="(op, index) in tr.Options"
                        :key="index"
                      >
                        <vs-input :v-model="op" />
                        <vs-button
                          @click="addOptions()"
                          color="success"
                          type="filled"
                          icon-pack="feather"
                          icon="icon-plus"
                          class="ml-1"
                        ></vs-button>
                        <vs-button
                          @click="removeOption(index)"
                          v-if="tr.Options.length > 1"
                          color="danger"
                          type="filled"
                          icon-pack="feather"
                          icon="icon-minus"
                          class="ml-1"
                        ></vs-button>
                      </div>
                    </div>
                  </vs-td>
                  <vs-td>
                    <vs-checkbox
                      v-model="tr.Required"
                      color="primary"
                    ></vs-checkbox>
                  </vs-td>
                  <vs-td>
                    <vs-checkbox
                      v-model="tr.Visible"
                      color="primary"
                    ></vs-checkbox>
                  </vs-td>
                  <vs-td>
                    <div class="merge-tag">
                      <span> <vs-input v-model="tr.Merge_Tag" /></span>
                      or
                      <span>*Merge{{ customsFields.length + 1 }}*</span>
                    </div>
                  </vs-td>
                  <vs-td>
                    <vs-input
                      v-model="tr.Default_Value"
                      placeholder="Default merge tag value"
                    />
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>-->
        </div>
      </div>
      <div class="float-right mt-6 mb-8">
        <vs-button
          type="filled"
          button="submit"
          color="primary"
          @click="addField()"
          >{{ LabelConstant.buttonLabelAdd }}</vs-button
        >
        <vs-button
          @click="cancelAddField()"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelClose }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Delete Field Modal -->
    <vs-popup class="delete-popup" :active.sync="deleteFeildActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Are you sure to delete this Feild?</h4>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button color="danger" type="filled" @click="deleteCustomField()"
          >{{ LabelConstant.buttonLabelDelete }}</vs-button
        >
        <vs-button
          color="grey"
          @click="(deleteFeildActive = false), (customFieldDeleteId = 0)"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import moment from "moment";
import VxCard from "@/components/vx-card/VxCard";
import vSelect from "vue-select";
import { Validator } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
Validator.extend("phone_number", {
  validate: (value) => {
    var strongRegex = new RegExp("^[0-9-]+$");
    return strongRegex.test(value);
  },
});
Validator.extend("zip_code", {
  validate: (value) => {
    var strongRegex = new RegExp("[0-9]{5}(?:-[0-9]{4})?$");
    return strongRegex.test(value);
  },
});

const dict = {
  custom: {
    fromName: {
      required: "Please enter default from name",
    },
    fromEmail: {
      required: "Please enter default from email address",
    },
    emailSubject: {
      required: "Please enter default email subject",
    },
    address1: {
      required: "Please enter street address",
    },
    cityName: {
      required: "Please enter city name",
    },
    stateName: {
      required: "Please enter state name",
    },
    zipNumber: {
      required: "Please enter zip",
    },
    country: {
      required: "Please select country",
    },
    phoneNumber: {
      required: "Please enter phone number",
    },
    websiteurl: {
      required: "Please enter url",
      url: "Please enter valid url ",
    },
    senderFromName: {
      required: "Please enter default from name",
    },
    senderFromEmail: {
      required: "Please enter default from email address",
    },
    senderSubject: {
      required: "Please enter default email subject",
    },
    senderAddress1: {
      required: "Please enter street address",
    },
    senderCity: {
      required: "Please enter city name",
    },
    senderState: {
      required: "Please enter state name",
    },
    senderZip: {
      required: "Please enter zip",
    },
    senderCountry: {
      required: "Please select country",
    },
    senderNumber: {
      required: "Please enter phone number",
    },
    senderWebsite: {
      required: "Please enter url",
      url: "Please enter valid url ",
    },
    friendlyname: {
      required: "Please enter friendly name",
    },
  },
};
Validator.localize("en", dict);
export default {
  components: {
    VxCard,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      senderId: 0,
      campaignId: 0,
      check: false,
      friendlyname: "",
      businessName: "",
      sendSummury: false,
      phoneNumber: "",
      fromName: "",
      fromEmail: "",
      emailSubject: "",
      address1: "",
      address2: "",
      stateName: "",
      cityName: "",
      zipName: "",
      zipNumber: "",
      sendEmail: "",
      websiteurl: "",
      senderFromName: "",
      senderFromEmail: "",
      senderSubject: "",
      senderAddress1: "",
      senderAddress2: "",
      senderCity: "",
      senderState: "",
      senderZip: "",
      senderCountry: null,
      senderNumber: "",
      senderWebsite: "",
      country: null,
      customFieldDeleteId: 0,
      addCustomField: {
        Id: 0,
        Field_Label: "",
        Field_Type_Label: "",
        Field_Type: "text",
        Required: true,
        Visible: true,
        Merge_Tag: "",
        Default_Value: null,
        Current_Value: "",
      },
      customOptions: [
        {
          Id: 0,
          Possible_Value: "",
        },
      ],
      CountryOptions: [],
      addSenderActive: false,
      deleteSenderActive: false,
      addFieldActive: false,
      deleteFeildActive: false,
      // notification tab
      notificationId: 0,
      Email_On_Unsubscribe: false,
      Email_On_Unsubscribe_Addresses: [
        {
          Unsubscribeemail: null,
        },
      ],
      Email_On_Contact_Change_Addresses: [
        {
          ContactChangeemail: null,
        },
      ],
      Email_On_Contact_Change: false,
      senders: [],
      importedData: [],
      customsFields: [],
    };
  },
  mounted() {
    this.getDefaultCampaignDetails();
    this.getCustomField();
    this.getDefaultNotificationDetails();
    this.getSenderDetails();
    this.getImportHistory();
    this.getAvailableCoutries();
  },
  methods: {
    firstCapital(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    changeEditImage(e, id) {
      this.imageCustomError = null;
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      let b64;
      setTimeout(() => {
        if (reader.result) {
          b64 = reader.result;
          b64 = b64.split("base64,");
          b64 = b64[1];
          let obj = this.customsFields.find((ele) => ele.Id == id);
          obj.Default_Value = b64;
        }
      }, 200);
    },
    changeImage(e) {
      this.imageCustomError = null;
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      let b64;

      setTimeout(() => {
        if (reader.result) {
          b64 = reader.result;
          b64 = b64.split("base64,");

          b64 = b64[1];
          this.addCustomField.Default_Value = b64;
        }
      }, 200);
    },
    addEditOptions(id) {
      let obj = this.customsFields.find((ele) => ele.Id == id);
      obj.Options.push({ Id: 0, Possible_Value: "" });
    },
    addOptions() {
      this.customOptions.push({ Id: 0, Possible_Value: "" });
    },
    removeEditOption(index, id) {
      let obj = this.customsFields.find((ele) => ele.Id == id);
      obj.Options.splice(index, 1);
    },
    removeOption(index) {
      this.customOptions.splice(index, 1);
    },
    customFieldType(type) {
      this.addCustomField.Field_Type = type;
      this.addCustomField.Field_Label = "";
      this.addCustomField.Field_Type_Label = "";
      this.addCustomField.Required = true;
      this.addCustomField.Visible = true;
      this.addCustomField.Merge_Tag = "";
      this.addCustomField.Default_Value = null;
      this.customOptions = [{ Id: 0, Possible_Value: "" }];
      this.addCustomField.Current_Value = "";
    },
    formattedDate(date) {
      return moment(date).format("DD/MM/YYYY hh:mm A");
    },
    addEmail(type) {
      if (type == "U") {
        this.Email_On_Unsubscribe_Addresses.push({
          Unsubscribeemail: "",
        });
      } else {
        this.Email_On_Contact_Change_Addresses.push({
          ContactChangeemail: null,
        });
      }
    },
    removeEmail(type, index) {
      if (type === "U") {
        this.Email_On_Unsubscribe_Addresses.splice(index, 1);
      } else {
        this.Email_On_Contact_Change_Addresses.splice(index, 1);
      }
    },
    async getDefaultCampaignDetails() {
      this.$vs.loading();
      await this.axios
        .get("/ws/EmailMarketingSettingsCampaignDefaults/GetDefaultSettings")
        .then((response) => {
          let data = response.data;
          this.fromName = data.From_Name;
          this.fromEmail = data.From_Email;
          this.emailSubject = data.Subject;
          this.address1 = data.Address1;
          this.address2 = data.Address2;
          this.cityName = data.City;
          this.stateName = data.State;
          this.zipNumber = data.Zip;
          this.country = data.Country;
          this.phoneNumber = data.Phone;
          this.websiteurl = data.Website;
          this.campaignId = data.Id;
          setTimeout(() => {
            this.errors.clear();
          }, 100);
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async updateDefaultCampaignDetails() {
      this.$validator.validateAll("defaultCampaign").then(async (result) => {
        if (result) {
          let input = {
            Id: this.campaignId,
            From_Name: this.fromName,
            From_Email: this.fromEmail,
            Subject: this.emailSubject,
            Address1: this.address1,
            Address2: this.address2,
            City: this.cityName,
            State: this.stateName,
            Zip: this.zipNumber,
            Country: this.country,
            Phone: this.phoneNumber,
            Website: this.websiteurl,
          };
          await this.axios
            .post(
              "/ws/EmailMarketingSettingsCampaignDefaults/UpdateDefaultSettings",
              input
            )
            .then(() => {
              setTimeout(() => {
                this.getDefaultCampaignDetails();
                this.errors.clear();
              }, 100);
              
          this.$vs.notify({
                title: "Success",
                text: "Campaign default updated successfully",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
              this.$vs.loading.close();
              this.showError(e);
            });
        }
      });
    },
    async updateDefaultNotificationSettings() {
      let unsubscribeEmail = [];
      let contactEmail = [];

      this.Email_On_Unsubscribe_Addresses.forEach((element) => {
        if (element.Unsubscribeemail) {
          unsubscribeEmail.push(element.Unsubscribeemail);
        }
      });
      this.Email_On_Contact_Change_Addresses.forEach((element) => {
        if (element.ContactChangeemail) {
          contactEmail.push(element.ContactChangeemail);
        }
      });
      let input = {
        Id: this.notificationId,
        Email_On_Unsubscribe: this.Email_On_Unsubscribe,
        Email_On_Unsubscribe_Addresses: unsubscribeEmail,
        Email_On_Contact_Change: this.Email_On_Contact_Change,
        Email_On_Contact_Change_Addresses: contactEmail,
      };
      await this.axios
        .post(
          "/ws/EmailMarketingSettingsNotifications/UpdateNotificationSettings",
          input
        )
        .then(() => {
          setTimeout(() => {
            this.getDefaultNotificationDetails();
            this.errors.clear();
          }, 100);
          this.$vs.loading.close();

          this.$vs.notify({
                title: "Success",
                text: "Notification updated successfully",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    async getDefaultNotificationDetails() {
      this.$vs.loading();
      await this.axios
        .get("/ws/EmailMarketingSettingsNotifications/GetNotificationSettings")
        .then((response) => {
          let data = response.data;
          this.Email_On_Contact_Change = data.Email_On_Contact_Change;
          this.Email_On_Unsubscribe = data.Email_On_Unsubscribe;
          if (data.Email_On_Unsubscribe_Addresses != null) {
            if (data.Email_On_Unsubscribe_Addresses.length > 0) {
              this.Email_On_Unsubscribe_Addresses = [];
              data.Email_On_Unsubscribe_Addresses.forEach((element) => {
                this.Email_On_Unsubscribe_Addresses.push({
                  Unsubscribeemail: element,
                });
              });
            }
          } else {
            this.Email_On_Unsubscribe_Addresses = [
              {
                Unsubscribeemail: null,
              },
            ];
          }
          if (data.Email_On_Contact_Change_Addresses) {
            if (data.Email_On_Contact_Change_Addresses.length > 0) {
              this.Email_On_Contact_Change_Addresses = [];
              data.Email_On_Contact_Change_Addresses.forEach((element) => {
                this.Email_On_Contact_Change_Addresses.push({
                  ContactChangeemail: element,
                });
              });
            }
          } else {
            this.Email_On_Contact_Change_Addresses = [];
            this.Email_On_Contact_Change_Addresses.push({
              ContactChangeemail: null,
            });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async getSenderDetails() {
      this.$vs.loading();
      await this.axios
        .get("/ws/EmailMarketingSettingsSender/ListSenders")
        .then((response) => {
          this.senders = response.data;
          setTimeout(() => {
            this.errors.clear();
          }, 100);
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    addUpdateSender() {
      this.$validator.validateAll("senderForm").then(async (result) => {
        if (result) {
          let url;
          let msg;
          if (this.senderId == 0) {
            url = "/ws/EmailMarketingSettingsSender/AddSender";
            msg = "Sender added Successfully";
          } else {
            url = "/ws/EmailMarketingSettingsSender/UpdateSender";
            msg = "Sender updated Successfully";
          }
          let input = {
            Id: this.senderId,
            Friendly_Name: this.friendlyname,
            From_Name: this.senderFromName,
            From_Email: this.senderFromEmail,
            Subject: this.senderSubject,
            Address1: this.senderAddress1,
            Address2: this.senderAddress2,
            City: this.senderCity,
            State: this.senderState,
            Zip: this.senderZip,
            Country: this.senderCountry,
            Phone: this.senderNumber,
            Website: this.senderWebsite,
          };
          await this.axios
            .post(url, input)
            .then(() => {
              setTimeout(() => {
                this.$vs.loading.close();
                this.errors.clear();
              }, 100);
              this.resetSender();
              this.getSenderDetails();
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Success",
                text: msg,
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
              this.showError(e);
            });
        }
      });
    },
    async getSender(id) {
      this.$vs.loading();
      await this.axios
        .get("ws/EmailMarketingSettingsSender/GetSender?Id=" + id)
        .then((response) => {
          let data = response.data;
          this.friendlyname = data.Friendly_Name;
          this.senderFromName = data.From_Name;
          this.senderFromEmail = data.From_Email;
          this.senderSubject = data.Subject;
          this.senderAddress1 = data.Address1;
          this.senderAddress2 = data.Address2;
          this.senderCity = data.City;
          this.senderState = data.State;
          this.senderZip = data.Zip;
          this.senderCountry = data.Country;
          this.senderNumber = data.Phone;
          this.senderWebsite = data.Website;
          this.senderId = data.Id;
          this.addSenderActive = true;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    resetSender() {
      setTimeout(() => {
        this.errors.clear();
      }, 100);
      this.addSenderActive = false;
      this.friendlyname = "";
      this.senderFromName = "";
      this.senderFromEmail = "";
      this.senderSubject = "";
      this.senderAddress1 = "";
      this.senderAddress2 = "";
      this.senderCity = "";
      this.senderState = "";
      this.senderZip = "";
      this.senderCountry = "";
      this.senderNumber = "";
      this.senderWebsite = "";
      this.senderId = 0;
    },
    deleteSenderConfirmation(tr) {
      this.senderId = tr.Id;
      this.senderFromName = tr.From_Name;
      this.deleteSenderActive = true;
    },
    async deleteSender() {
      this.$vs.loading();
      await this.axios
        .get(
          "/ws/EmailMarketingSettingsSender/DeleteSender?Id=" + this.senderId
        )
        .then(() => {
          this.senderId = 0;
          this.senderFromName = "";
          this.deleteSenderActive = false;
          this.$vs.notify({
            title: "Success",
            text: "Sender Deleted Successfully",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.getSenderDetails();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    async getImportHistory() {
      this.$vs.loading();
      await this.axios
        .get("/ws/EmailMarketingSettingsImportHistory/GetImportHistory")
        .then((response) => {
          let data = response.data;
          this.importedData = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async undoImport(id) {
      await this.axios
        .post("/ws/EmailMarketingSettingsImportHistory/UndoImport?id=" + id)
        .then(() => {
          this.getImportHistory();
          setTimeout(() => {
            this.errors.clear();
          }, 100);
          this.$vs.notify({
            title: "Success",
            text: "Import undoed successfully",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    async getCustomField() {
      this.$vs.loading();
      this.customsFields = [];
      await this.axios
        .get("/ws/EmailMarketingSettingsCustomFields/ListCustomFields")
        .then((response) => {
          let data = response.data;
          data.forEach((e) => {
            e.Edit = false;
            if (
              e.Field_Type.Field_Type == "radio" ||
              e.Field_Type.Field_Type == "dropdown"
            ) {
              if (e.Options.length == 0) {
                e.Options.push({ Id: 0, Possible_Value: "" });
              }
            }
          });
          this.customsFields = data;

          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    editCustomField(id) {
      let obj = this.customsFields.find((ele) => ele.Id == id);
      obj.Edit = true;
    },
    async deleteCustomField() {
      await this.axios
        .get(
          "/ws/EmailMarketingSettingsCustomFields/DeleteCustomField?Id=" +
            this.customFieldDeleteId
        )
        .then(() => {
          this.deleteFeildActive = false;
          this.customFieldDeleteId = 0;
          this.getCustomField();
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Success",
            text: "Custom field deleted successfully",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async addField() {
      // let options = [];
      // if (this.customOptions.length > 0) {
      //   this.customOptions.forEach((el) => {
      //     if (el.coption) {
      //       options.push(el.coption);
      //     }
      //   });
      // }
      let input = {
        Id: 0,
        Field_Label: this.addCustomField.Field_Label,
        Field_Type_Label: this.addCustomField.Field_Label,
        Field_Type: this.addCustomField.Field_Type,
        Required: this.addCustomField.Required,
        Visible: this.addCustomField.Visible,
        Merge_Tag: this.addCustomField.Merge_Tag,
        Default_Value: this.addCustomField.Default_Value,
        Options: this.customOptions,
        Current_Value: "",
      };
      await this.axios
        .post("/ws/EmailMarketingSettingsCustomFields/AddCustomFields", input)
        .then(() => {
          setTimeout(() => {
            this.addFieldActive = false;
            this.addCustomField.Field_Type = "text";
            this.addCustomField.Field_Label = "";
            this.addCustomField.Field_Type_Label = "";
            this.addCustomField.Required = true;
            this.addCustomField.Visible = true;
            this.addCustomField.Merge_Tag = "";
            this.addCustomField.Default_Value = null;
            this.customOptions = [{ Id: 0, Possible_Value: "" }];
            this.addCustomField.Current_Value = "";
            this.getCustomField();
            this.errors.clear();
          }, 100);
           this.$vs.notify({
                title: "Success",
                text: "Custom field added successfully",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async updateCustomField(tr) {
      // let op = [];
      //  if (tr.Options.length > 0) {
      //   tr.Options.forEach((el) => {
      //     if (el.coption) {
      //       op.push(el.coption);
      //     }
      //   });
      // }
      // tr.Options = op;
      let input = tr;
      await this.axios
        .post(
          "/ws/EmailMarketingSettingsCustomFields/UpdateCustomFields",
          input
        )
        .then(() => {
          setTimeout(() => {
            this.getCustomField();
            this.errors.clear();
          }, 100);
           this.$vs.notify({
                title: "Success",
                text: "Custom field updated successfully",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    cancelAddField() {
      this.addFieldActive = false;
      this.addCustomField.Field_Type = "text";
      this.addCustomField.Field_Label = "";
      this.addCustomField.Field_Type_Label = "";
      this.addCustomField.Required = true;
      this.addCustomField.Visible = true;
      this.addCustomField.Merge_Tag = "";
      this.addCustomField.Default_Value = null;
      this.customOptions = [{ Id: 0, Possible_Value: "" }];
      this.addCustomField.Current_Value = "";
      setTimeout(() => {
        this.errors.clear();
      }, 100);
    },
    async getAvailableCoutries() {
      this.$vs.loading();
      await this.axios
        .get("/ws/EmailMarketingSettingsCampaignDefaults/GetAvailableCountries")
        .then((response) => {
          let data = response.data;
          this.CountryOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
  },
};
</script>
